
import { Vue, Component, Watch } from "vue-property-decorator";
import QrCode from "@/components/qrcode.vue";
import { IOrderAction, IOrderList, IOrderShow } from "@/services/models/order";
import { OrderAction, OrderShowOneRequest } from "@/services/api/ApiOrder";
import User from "@/store/modules/User";
import {
  notificationError,
  notificationSuccess
} from "@/utils/NotificationUtils";
interface QrReadData {
  order_id: number;
  user: { id: number; name: string };
}

@Component({ components: { QrCode } })
export default class PickOrders extends Vue {
  openQrReader = false;
  readOrder = false;
  readBoxes = false;
  order: IOrderShow = {} as IOrderShow;
  openForm = false;
  deliveredBy?: { id: number; name: string };
  form: IOrderAction = {
    order_id: null,
    package_check: false,
    risk_label_check: false,
    amount_check: false,
    released_by: null,
    release_by_name: "",
    collected_by: null,
    temperature: null,
    observation: "",
    action_type_id: 3
  };

  // @Watch("order.boxes")
  // checkAllBoxes(): void {
  //   const index = this.order.boxes.findIndex((box) => !box.checked);
  //   if (index == -1) this.form.amount_check = true;
  // }

  fields: { key: string; label: string }[] = [
    {
      key: "id",
      label: "Nº"
    },
    {
      key: "checked",
      label: `Verificada`
    },
    {
      key: "actions",
      label: `Ações`
    }
  ];

  created(): void {
    this.form.collected_by = User.user?.id as number;
  }

  closeQrReader(): void {
    this.openQrReader = false;
    this.readOrder = false;
    this.readBoxes = false;
  }

  openReader(): void {
    this.openQrReader = true;
  }

  async onReadCode(value: string): Promise<void> {
    try {
      if (this.readOrder) {
        const data: QrReadData = JSON.parse(value);
        this.order = await OrderShowOneRequest(data.order_id);
        this.deliveredBy = data.user;
      }
      if (this.readBoxes) {
        const newOrder: IOrderShow = JSON.parse(JSON.stringify(this.order));
        const box = newOrder.boxes.find((box) => box.code == value);
        if (box) box.checked = true;
        this.order = newOrder;
        //   this.$refs.table?.refresh();
      }
    } catch (error) {
      notificationError("Código incorreto!");
    }
  }
  resetPage(): void {
    this.openQrReader = false;
    this.readOrder = false;
    this.readBoxes = false;
    this.order = {} as IOrderShow;
    this.openForm = false;
    this.form = {
      order_id: null,
      package_check: false,
      risk_label_check: false,
      amount_check: false,
      released_by: null,
      release_by_name: "",
      collected_by: null,
      temperature: null,
      observation: "",
      action_type_id: 3
    };
  }

  async pickOrder(): Promise<void> {
    await OrderAction({
      ...this.form,
      released_by: this.deliveredBy?.id as number,
      order_id: this.order.id
    });
    notificationSuccess("Pedido recebido com sucesso.");
    this.resetPage();
  }
}
